import { SkinSlot } from '../utils/skin';
import { ClassType } from '../models/enums/class.type';

export const environment = {
  defaultUnprotectedRoute: '/',
  defaultProtectedRoute: '/main',
  zustandStoreVersion: 3,
  config: {
    enableCoins: true,
    enableUpgrades: false,
    enableSkills: false,
    skinSlots: [
      {
        slot: SkinSlot.Body,
        shortName: 'up',
        name: 'Subir de nivel',
      },
    ],
    classTypes: [
      ClassType.Mage,
      ClassType.Archer,
      ClassType.Warrior,
    ],
    classNames: {
      [ClassType.Mage]: {
        maleName: 'Íris ',
        femaleName: 'Íris ',
      },
      [ClassType.Archer]: {
        maleName: 'César',
        femaleName: 'César',
      },
      [ClassType.Warrior]: {
        maleName: 'Diana ',
        femaleName: 'Diana ',
      }
    }
  },
  api: {
    baseUrl: process.env.REACT_APP_BASE_URL || 'https://api.calouros.ligafacens.com',
    auth: {
      local: '/auth/{authType}',
    },
    user: {
      me: '/users/me',
      create: '/users',
      selectClass: '/users/{userId}/class-type',
      update: '/users/{userId}',
      ranking: '/users/{userId}/ranking',
      listRanking: '/users/ranking',
      skins: '/users/skins?join=skin&limit=1000',
      buySkin: '/users/{userId}/skins/{skinId}',
      buySkinUpgrade: '/users/{userId}/skins/{skinId}/upgrades/{skinUpgradeId}',
    },
    quizes: {
      list: '/quizes?join=place&join[0]=questions&join[1]=questions.answers&limit=1000',
      sendAnswer: '/users/questions/{questionId}/answers',
    },
    learnStyles: {
      list: '/learn-styles?limit=1000',
      answers: '/learn-styles/answers',
    },
    odysseys: {
      list: '/odysseys?limit=1000',
      answers: '/odyssey-answers'
    },
    missions: {
      id: '/missions/{id}',
      list: '/missions?join=place&limit=1000',
      verifyQrCode: '/mission/answers/verify/qrcode/{missionId}',
    },
    skins: {
      list: '/skins?s={searchParams}&limit=1000'
    }
  },
};
